@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
  font-family: 'LEMON MILK Pro FTR';
  src: url('/fonts/Lemon Milk Pro Regular.otf');
}

@font-face {
  font-family: 'LEMON MILK Pro FTR Bold';
  src: url('/fonts/Lemon Milk Pro Bold.otf');
}
@font-face {
  font-family: 'LEMON MILK Pro FTR Medium';
  src: url('/fonts/Lemon Milk Pro Medium.otf');
}
@font-face {
  font-family: 'LEMON MILK Pro FTR Ultra Light';
  src: url('/fonts/Lemon Milk Pro Ultra Light.otf');
}

/* purgecss start ignore */
html,
body {
  font-family: 'Work Sans', sans-serif;
  background-color: #f8f9fe;
  color: black;
}

/* custom spinner */

.spinner {
  animation: spin 1s ease infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* remove input border, no se quitaba con tw */
input:hover,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

/* carousel */
.carousel-img {
  transition: 1s;
}

.carousel-present {
  left: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
}

.carousel-next {
  position: absolute;
  left: 0vw;

  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s;
}

/* Scrollbar */
/* *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
} */
*::-webkit-scrollbar-track {
  background-color: #dedede;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #375fdb;
  border-radius: 14px;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #375fdb;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #375fdb, 0 0 5px #375fdb;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #375fdb;
  border-left-color: #375fdb;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* purgecss end ignore */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 31.3 86.7% 46.5%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

/* h1 {
  @apply font-lemonBold font-bold;
}

p {
  @apply font-lemon text-sm;
}

body {
  @apply font-lemon font-extralight;
} */
